import React from 'react'
import { graphql } from 'gatsby'
import Page from '../templates/page'

export default ({ data: { mdx } }) => {
  return <Page mdx={mdx} />
}

export const query = graphql`
  query {
    mdx(fileAbsolutePath: { regex: "/about/" }, frontmatter: { published: { eq: true } }) {
      id
      frontmatter {
        title
      }
      body
    }
  }
`
